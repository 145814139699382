/* MediaPlayer.css */
body {
    overflow: hidden;
}

.media-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.media-container video {
    object-fit: initial;
    background-color: white;
    display: block;
    margin: auto;
    width: 100%;
    height: 100vh;
}